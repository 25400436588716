import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { SunIcon } from "@heroicons/react/solid";

import Img01 from "../images/vystavba/12-21-01.jpeg";
import Img02 from "../images/vystavba/12-21-02.jpeg";
import Img03 from "../images/vystavba/12-21-03.jpeg";
import Img04 from "../images/vystavba/12-21-04.jpeg";
import Img05 from "../images/vystavba/12-21-05.jpeg";
import Img07 from "../images/vystavba/18-03-01.jpg";
import Img06 from "../images/vystavba/18-03-02.jpg";
import Img08 from "../images/vystavba/18-03-03.jpg";
import Img09 from "../images/vystavba/18-03-04.jpg";
import Img10 from "../images/vystavba/18-03-05.jpg";
import Img11 from "../images/vystavba/15-06-22-1.jpg";
import Img12 from "../images/vystavba/15-06-22-2.jpg";
import Img13 from "../images/vystavba/15-06-22-3.jpg";
import Img14 from "../images/vystavba/05-12-1.jpg";
import Img15 from "../images/vystavba/05-12-2.jpg";
import Img16 from "../images/vystavba/05-12-3.jpg";
import Img17 from "../images/vystavba/05-12-4.jpg";
import Img18 from "../images/vystavba/05-12-5.jpg";
import { SRLWrapper } from "simple-react-lightbox";

const files = [
    { name: "5. 12. 2022", divider: true },
    { name: "Výstavba", url: Img14 },
    { name: "Výstavba", url: Img15 },
    { name: "Výstavba", url: Img16 },
    { name: "Výstavba", url: Img17 },
    { name: "Výstavba", url: Img18 },
    { name: "15. 6. 2022", divider: true },
    { name: "Výstavba", url: Img11 },
    { name: "Výstavba", url: Img12 },
    { name: "Výstavba", url: Img13 },
    { name: "18. 03. 2022", divider: true },
    { name: "Výstavba", url: Img06 },
    { name: "Výstavba", url: Img07 },
    { name: "Výstavba", url: Img08 },
    { name: "Výstavba", url: Img09 },
    { name: "Výstavba", url: Img10 },
    { name: "15. 12. 2021", divider: true },
    { name: "Výstavba", url: Img04 },
    { name: "Výstavba", url: Img05 },
    { name: "6. 12. 2021", divider: true },
    { name: "Výstavba", url: Img01 },
    { name: "Výstavba", url: Img02 },
    { name: "Výstavba", url: Img03 },
];

const StavebniPlanPage = ({ location }) => {
    // Move this to it's own component consuming context.

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Stavební plán | Domy Trio</title>
            </Helmet>
            <Layout location={location}>
                <main>
                    <div className="mx-auto w-full   relative z-30 ">
                        <div className="mx-auto max-w-6xl w-full p-8 lg:px-14 z-auto  backdrop-filter  bg-white bg-opacity-90 relative shadow-md border border-gray-200 ">
                            <svg className="absolute w-60 h-60 text-yellow-400 top-0 right-0 ">
                                <polygon
                                    points="0,0 300,0 300,300"
                                    class="triangle"
                                    fill="currentColor"
                                />
                                <SunIcon
                                    width="50"
                                    x="160"
                                    y="-70"
                                    fill="#ffffff"
                                />
                            </svg>
                            <div className="relative">
                                <h1 className="text-xl lg:text-3xl font-merri mb-10 border-b border-yellow-400 pb-3">
                                    Průběh výstavby
                                </h1>
                                <SRLWrapper>
                                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
                                        {files.map((file, index) => {
                                            if (file?.divider) {
                                                return (
                                                    <div className="col-span-2 lg:col-span-4 text-lg">
                                                        {file.name}
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div
                                                        className={
                                                            "overflow-hidden shadow-md border hover:border-gray-500 "
                                                        }
                                                        key={index}
                                                    >
                                                        <img
                                                            className="cursor-pointer object-cover min-h-full "
                                                            src={file.url}
                                                            alt={file.name}
                                                        />
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                </SRLWrapper>
                            </div>
                        </div>
                    </div>
                </main>
            </Layout>
        </>
    );
};

export default StavebniPlanPage;
